import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import Apercu from './fonts/Apercu-Regular-Pro.woff2';
import ApercuLight from './fonts/Apercu-Light-Pro.woff2';

const theme = createTheme({
    palette: {
        primary: {
            main: '#8223D2',
        },
        secondary: {
            main: '#000000',
        },
        white: {
            main: '#ffffff',
        },
        error: {
            main: red.A400,
        },
        text: {
            primary: '#000000',
            secondary: '#8223D2',
            asurion: '#621A9E',
            white: '#ffffff',
            priceGreen: '#046840',
            priceRed: '#D91B1B',
            disabled: '#979797',
            fineprint: '#6C6C6C',
            primaryv4: '#671CA6',
        },
        typography: {
            fontSize: 16,
            fontFamily: ['Apercu', 'sans-serif'].join(','),
            fontDisplay: 'swap',
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [Apercu],
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            tiny: 375,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            ssm: 700,
        },
    },
    typography: {
        fontFamily: 'Apercu, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Apercu';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Apercu'), local('Apercu-Regular-Pro'), url(${Apercu}) format('woff2');
              font-display: swap;
            }

            @font-face {
                font-family: 'ApercuLight';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: local('ApercuLight'), local('Apercu-Light-Pro'), url(${ApercuLight}) format('woff2');
                font-display: swap;
                }
          `,
        },
    },
});

export default theme;
