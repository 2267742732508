import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Image from '../../src/assets/asurion-logo.svg';

import theme from '../../src/theme';

export default function TopLayout(props) {
    var meta = [];
    return (
        <React.Fragment>
            <Helmet
                meta={[
                    {
                        name: `description`,
                        content: 'metaDescription',
                    },
                    {
                        name: `image`,
                        content: Image,
                    },
                    {
                        property: `og:title`,
                        content: 'OG title',
                    },
                    {
                        property: `og:description`,
                        content: 'OG metaDescription',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `og:image`,
                        content: Image,
                    },
                    {
                        name: `twitter:card`,
                        content: `twitter summary_large_image`,
                    },

                    {
                        name: `twitter:title`,
                        content: 'twitter title',
                    },
                    {
                        name: `twitter:description`,
                        content: 'twitter metaDescription',
                    },
                    {
                        name: `format-detection`,
                        conent: `telephone=no`,
                    },
                ].concat(meta)}
            >
                <meta http-equiv="Cache-Control" content="max-age: 31536000" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <meta name="icon" href="/favicon.ico" />
                <meta name="google-site-verification" content={process.env.GATSBY_GOOGLE_VERIFICATION_ID} />
                <meta name="ahrefs-site-verification" content={process.env.GATSBY_AHREF_VERIFICATION_ID} />
            </Helmet>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </React.Fragment>
    );
}

TopLayout.propTypes = {
    children: PropTypes.node,
};
