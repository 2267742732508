import React from 'react';
import { BLOCKS, MARKS, INLINES, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import * as DOMPurify from 'dompurify';

const TOKENS = {
    CITY: '<CITY>',
};
const isBrowser = typeof window !== 'undefined';

export const substituteCityName = (block, city) => {
    let formatted;
    const token = TOKENS.CITY;

    if (city) {
        if (typeof block === 'string') {
            formatted = block.replaceAll(token, city);
        } else if (typeof block === 'object') {
            formatted = block;
        }
    } else {
        if (typeof block === 'string') {
            formatted = block.replaceAll(token, '');
        } else if (typeof block === 'object') {
            formatted = block;
        }
    }

    return formatted;
};

export const repairRenderingOptions = (cityName, classNames) => {
    return {
        renderMark: {
            [MARKS.BOLD]: (children) => {
                return <b>{substituteCityName(children, cityName)}</b>;
            },
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Box component="p" className={classNames.repairParagraphs}>
                        {children.map((child, i) => {
                            return substituteCityName(child, cityName);
                        })}
                    </Box>
                );
            },
        },
    };
};

export const isEnabledHoliday = (holiday) => {
    const enabledHoliday = process.env.ENABLED_HOLIDAY_FEATURES;

    return enabledHoliday.indexOf(holiday) > -1 ? true : false;
};

export const isNewDesignEnabled = () => {
    const designVersion = process.env.DESIGN_VERSION;

    return designVersion >= 3.5 ? true : false;
};

// export const redirectWithParams = (url, direction) => {
//     const isBrowser = typeof window !== 'undefined';
//     if (isBrowser) {
//         if (direction === 'reverse') {
//             if (window.location.pathname === '/') {
//                 url = `${window.location.search}${url}`;
//             } else {
//                 url = `/${window.location.search}${url}`;
//             }
//         } else {
//             url = `${url}${window.location.search}`;
//         }
//         return `${url}`;
//     } else {
//         return `${url}`;
//     }
// };

export const redirectWithParams = (url) => {
    if (isBrowser) {
        let paramsFromCookies = document.cookie;
        let paramsFromCookiesCleaned = paramsFromCookies.replaceAll(' ', '').replaceAll(';', '&');
        let existingParamsInUrl = url.indexOf('?');
        var newUrl = '';
        let partnerPageUTM = '&utm_source=google&utm_medium=cpc&utm_campaign=SPS&utm_id=D012345';
        let partnerPageURLPath = '/sps';
        let toAppendValue = window.location.pathname.includes(partnerPageURLPath) ? partnerPageUTM : '';

        if (existingParamsInUrl !== -1) {
            //url has ? so we append cookies as &
            newUrl = url.concat('&' + paramsFromCookiesCleaned + '&src_site=' + window.location.href + toAppendValue);
        } else {
            if (paramsFromCookiesCleaned === '') {
                newUrl = url.concat('?src_site=' + window.location.href + toAppendValue);
            } else {
                newUrl = url.concat(
                    '?' + paramsFromCookiesCleaned + '&src_site=' + window.location.href + toAppendValue
                );
            }
        }

        return `${newUrl}`;
    } else {
        return `${url}`;
    }
};

export const isActiveFromDates = (dateStart, dateEnd) => {
    const bannerVisible = false;
    return bannerVisible;
};

export function htmlDecode(html) {
    return html.replace(/&([a-z]+);/gi, (match, entity) => {
        const entities = { amp: '&', apos: "'", gt: '>', lt: '<', nbsp: '\xa0', quot: '"' };
        entity = entity.toLowerCase();
        if (entities.hasOwnProperty(entity)) {
            return entities[entity];
        }
        return match;
    });
}

const Bold = ({ children }) => (
    <Typography component="span" fontWeight="bold">
        {children}
    </Typography>
);
const Italic = ({ children }) => (
    <Typography component="span" fontStyle="italic" color="text.secondary">
        {children}
    </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

// export const options = {
//     renderMark: {
//         [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
//         [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
//         [MARKS.CODE]: (text) => parse(`${text}`),
//     },
//     renderNode: {
//         [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
//         [INLINES.HYPERLINK]: (node, children) => <MuiLink href={node.data.uri}>{children}</MuiLink>,
//         [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
//         [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
//         [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
//     },
//     renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
// };

export const options = {
    renderText: (text) => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
        [MARKS.CODE]: (text) => parse(`${text}`),
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [INLINES.HYPERLINK]: (node, children) => <MuiLink href={node.data.uri}>{children}</MuiLink>,
    },
};

export const getBuyflowLink = () => {
    if (isBrowser) {
        if (window.location.hostname === 'www.asurion.com.au') {
            return 'https://enroll.asurion.com.au/';
        } else {
            return process.env.GATSBY_EXTERNAL_BUYFLOW_URL;
        }
    }
};

export const getMyAsurionLink = () => {
    if (isBrowser) {
        if (window.location.hostname === 'www.asurion.com.au') {
            return 'https://my.asurion.com.au/';
        } else {
            return process.env.GATSBY_EXTERNAL_MYASURION_URL;
        }
    }
};

export const checkCookieName = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    } else {
        return null;
    }
};

export const toUppercase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const svgUrlToComponent = (url, name) => {
    return <object type="image/svg+xml" data={url}></object>;
};

export const getSiteURL = (location, partnerInfo) => {
    let siteSrc =
        typeof location !== 'undefined'
            ? typeof location.href !== 'undefined'
                ? DOMPurify.sanitize(location.href)
                : process.env.GATSBY_DOMAIN_URL + '/' + partnerInfo.partnerSlug
            : process.env.GATSBY_DOMAIN_URL;
    return siteSrc;
};
export const convertToSlug = (text) => {
    return text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
};
