exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-how-do-i-js": () => import("./../../../src/pages/HowDoI.js" /* webpackChunkName: "component---src-pages-how-do-i-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-to-myasurion-tsx": () => import("./../../../src/pages/login-to-myasurion.tsx" /* webpackChunkName: "component---src-pages-login-to-myasurion-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-supported-phones-js": () => import("./../../../src/pages/supported-phones.js" /* webpackChunkName: "component---src-pages-supported-phones-js" */),
  "component---src-pages-switch-tsx": () => import("./../../../src/pages/switch.tsx" /* webpackChunkName: "component---src-pages-switch-tsx" */),
  "component---src-templates-brand-post-template-js": () => import("./../../../src/templates/BrandPostTemplate.js" /* webpackChunkName: "component---src-templates-brand-post-template-js" */),
  "component---src-templates-category-article-template-js": () => import("./../../../src/templates/CategoryArticleTemplate.js" /* webpackChunkName: "component---src-templates-category-article-template-js" */),
  "component---src-templates-review-template-js": () => import("./../../../src/templates/ReviewTemplate.js" /* webpackChunkName: "component---src-templates-review-template-js" */),
  "component---src-templates-single-article-template-js": () => import("./../../../src/templates/SingleArticleTemplate.js" /* webpackChunkName: "component---src-templates-single-article-template-js" */),
  "component---src-templates-single-how-to-template-js": () => import("./../../../src/templates/SingleHowToTemplate.js" /* webpackChunkName: "component---src-templates-single-how-to-template-js" */),
  "component---src-templates-single-page-template-js": () => import("./../../../src/templates/SinglePageTemplate.js" /* webpackChunkName: "component---src-templates-single-page-template-js" */)
}

