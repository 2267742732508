import './src/styles/global.css';
import { checkCookieName } from './src/utils/Utils';
import * as DOMPurify from 'dompurify';

const isBrowser = typeof window !== 'undefined';

// const initGTM = () => {
//     if (window.isGTMLoaded) {
//         return false;
//     }

//     window.isGTMLoaded = true;

//     const script = document.createElement('script');

//     script.type = 'text/javascript';
//     script.async = true;
//     script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTM_ID}`;

//     script.onload = () => {
//         window.dataLayer = window.dataLayer || [];
//         const gtag = () => {
//             window.dataLayer.push({
//                 // ensure PageViews are always tracked
//                 event: 'gtm.js',
//                 'gtm.start': new Date().getTime(),
//                 'gtm.uniqueEventId': 0,
//             });
//         };
//         gtag('js', new Date());

//         gtag('config', `${process.env.GATSBY_GTM_ID}`);
//     };

//     document.head.appendChild(script);
// };

// const loadGTM = (event) => {
//     initGTM();
//     event.currentTarget.removeEventListener(event.type, loadGTM);
// };

// export const onClientEntry = () => {
//     console.log('onClientEntry for GTM');
//     document.onreadystatechange = () => {
//         if (document.readyState !== 'loading') {
//             setTimeout(initGTM, 3500);
//         }
//     };

//     document.addEventListener('DOMContentLoaded', loadGTM);
//     document.addEventListener('scroll', loadGTM);
//     document.addEventListener('mousemove', loadGTM);
//     document.addEventListener('touchstart', loadGTM);
// };

const getParam = (p) => {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const getExpiryRecord = (value) => {
    var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

    var expiryDate = new Date().getTime() + expiryPeriod;
    return {
        value: value,
        expiryDate: expiryDate,
    };
};

const addGclid = () => {
    var gclidParam = getParam('gclid');
    var gclidFormFields = ['gclid_field', 'foobar']; // all possible gclid form field ids here
    var gclidRecord = null;
    var currGclidFormField;

    var gclsrcParam = getParam('gclsrc');
    var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

    gclidFormFields.forEach(function (field) {
        if (document.getElementById(field)) {
            currGclidFormField = document.getElementById(field);
        }
    });

    if (gclidParam && isGclsrcValid) {
        gclidRecord = getExpiryRecord(gclidParam);
        localStorage.setItem('gclid', JSON.stringify(gclidRecord));
    }

    var gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
    var isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

    if (currGclidFormField && isGclidValid) {
        currGclidFormField.value = gclid.value;
    }
};

window.addEventListener('load', addGclid);

export function shouldUpdateScroll(prevRouterProps, { location }) {
    window.scrollTo(0, 0);
    const body = document.getElementsByTagName('body')[0];
    body.scrollTop = 0;
    return false;
}

const pushToDataLayer = (event) => {
    if (isBrowser) {
        window.dataLayer.push({
            event: event,
            GCLID: checkCookieName('gclid') || null,
            GAClientID:
                (checkCookieName('_ga') && checkCookieName('_ga').substring(checkCookieName('_ga').indexOf('.') + 3)) ||
                null,
            UTMParams: {
                utm_source: checkCookieName('utm_source') || null,
                utm_content: checkCookieName('utm_content') || null,
                utm_id: checkCookieName('utm_id') || null,
                utm_campaign: checkCookieName('utm_campaign') || null,
                utm_medium: checkCookieName('utm_medium') || null,
                utm_term: checkCookieName('utm_term') || null,
            },
        });
    }
};

export const onClientEntry = () => {
    const allowedParams = [
        'utm_term',
        'utm_content',
        'utm_id',
        'utm_campaign',
        'utm_medium',
        'gclid',
        'utm_source',
        'fbclid',
    ];

    window.onload = () => {
        let params = DOMPurify.sanitize(window.location.search);
        var hasViews = null;
        let partnerPageURLPath = '/sps';
        if (params) {
            let segregatedParams = params.split('?')[1].split('&');
            for (let i = 0; i < segregatedParams.length; i++) {
                console.log(segregatedParams[i]);
                if (allowedParams.some((allowedParam) => allowedParam === segregatedParams[i].split('=')[0])) {
                    if (segregatedParams[i].includes('view=')) {
                        hasViews = segregatedParams[i];
                    } else {
                        document.cookie = DOMPurify.sanitize(
                            `${segregatedParams[i].split('=')[0]}=; expires = Thu, 01 Jan 1970 00:00:00 GMT`
                        );
                        document.cookie = DOMPurify.sanitize(segregatedParams[i]);
                    }
                }
            }
            console.log(window.location.pathname.includes(partnerPageURLPath));
            if (window.location.pathname.includes(partnerPageURLPath)) {
                console.log(document.cookie);
                document.cookie = 'utm_source=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
                document.cookie = 'utm_medium=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
                document.cookie = 'utm_campaign=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
                console.log(document.cookie);
            }

            if (hasViews) {
                window.history.pushState({}, document.title, window.location.href.split('?')[0] + '?' + hasViews);
            } else {
                window.history.pushState({}, document.title, window.location.href.split('?')[0]);
            }
            addGclid();
        }
        window.dataLayer = window.dataLayer || [];
        pushToDataLayer('Home_DL_Event Gclid Capture');
    };
};

export const onInitialClientRender = () => {
    // window.addEventListener('popstate', () => (window.location.href = window.location.href));
};
